<template>
  <div>
    <grid-layout
      ref="gridLayoutVide"
      class="serviceType"
      :table-options="subTableOption"
      :table-data="tableData"
      :table-loading="false"
    >
      <template #customBtn="{ row, index }">
        <el-button size="small" @click="beginTraining(row)" type="text"
                   v-if="row.schedule === '0%'&&type != 'view'&&!doned"
        > 开始培训
        </el-button>
        <el-button size="small" @click="beginTraining(row)" type="text"
                   v-if="row.schedule !== '0%'&&type != 'view'&&!doned"
        > 继续培训
        </el-button>
        <el-button size="small" @click="beginTraining(row)" type="text"
                   v-if="type == 'view' || doned"
        > 查看视频
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getDetail, myTrainingDetail} from "@/api/training/trainingEducation";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  props: {
    type: {
      type: "String"
    }
  },
  data() {
    return {
      dataForm: {},
      tableData: [],
      videoData: [],
      dicData: [],
      //视频列表展示字段
      subTableOption: {
        selection: false,
        customAddBtn: true,
        menuWidth: 100,
        column: [
          {
            label: "课程名称",
            prop: 'videoName',
            align: "left",
            overHidden: true,
          },
          {
            label: "时长",
            prop: 'duration',
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "进度",
            prop: 'schedule',
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: '是否必修',
            prop: 'isCompulsory',
            type: 'switch',
            props: {
              label: "label",
              value: "value"
            },
            disabled: !['edit', 'add'].includes(this.$route.query.type),
            dataType: 'string',
            cell: true,
            align: "center",
            width: 180,
            overHidden: true,
            dicData: [
              {
                label: '否',
                value: "2"
              },
              {
                label: '是',
                value: "1",
              },
            ],
          },
          {
            label: "获得课时",
            prop: 'obtainHours',
            align: "center",
            width: 180,
            overHidden: true,
          },
        ]
      },
      doned: false,
    }
  },
  computed: {},
  methods: {
    beginTraining(row) {

      // 开始培训
      // let id = row.id;
      // let timeParts = row.duration.split(':');
      // let duration = parseInt(timeParts[0]) * 3600 + parseInt(timeParts[1]) * 60 + parseInt(timeParts[2]);
      // let progress = '2';
      // let watchDuration = row.watchDuration
      // if (row.watchDuration === duration) {
      //   watchDuration = '0'
      // }
      // if (row.schedule == "100%") {
      //   progress = '1';
      // }
      // if (row.fileId) {
      //   this.videoData.forEach((e) => {
      //     if (e.id === row.fileId) {
      //       row = e
      //     }
      //   })
      //   this.videoShow = true;
      // }
      window.open( window.location.origin + `#/trainingBrowse?videoId=${row.id}&id=${this.dataForm.id}&fileType=${row.fileType}&isNewWindow=true`);
      // window.open(  `http://localhost:1888#/trainingBrowse?videoId=${row.id}&id=${this.dataForm.id}&fileType=${row.fileType}`);
    },
    /**
     * 查看详情
     * @param id
     */
    detail(id) {
      this.tableData = [];
      getDetail(id).then((res) => {
        this.dataForm = res.data.data;
        this.tableData = res.data.data.educationVideos
      }).then(() => {
        this.dataForm.taskId
        myTrainingDetail(this.dataForm.taskId).then((res) => {
          let data = res.data.data
          this.videoData = data.kmInfoVideoList
        })
      })
    },
  },
  mounted () {
    this.doned = this.$route.query.doned && this.$route.query.doned==1
  }
}
</script>
<style scoped>

</style>
