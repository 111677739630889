<template>
  <div>
    <head-layout
      :head-btn-options="subTableheadBtnOptions"
      head-title=""
      @head-save="headSave"
    ></head-layout>
    <grid-layout
      ref="gridLayoutVide"
      class="serviceType"
      :table-options="subTableOption"
      :table-data="tableData"
      :table-loading="false"
    >
      <template #customBtn="{ row, index }">
        <el-button size="small" @click="answer(row)" type="text"
                   v-if="row.isQualified==2&&(type != 'view'&&type!='additionalRecording'&&type!='recordScores') && !doned"
        > 答题
        </el-button>
        <el-button size="small" @click="answerOutcome(row)" type="text"
                   v-if="row.isQualified==1 && type!='additionalRecording'&&type!='recordScores'&&row.storageMode!='additionalRecording'"
        > 答题结果
        </el-button>
        <el-button size="small" @click="headSave(row)" type="text"
                   v-if="type=='additionalRecording' && !doned"
        > 保存
        </el-button>
        <el-button size="small" @click="recordScores(row)" type="text"
                   v-if="type=='recordScores' && !doned"
        > 成绩上传
        </el-button>
      </template>
    </grid-layout>
    <CommonDialog v-if="showFileDialog" :showConfirmBtn="false" width="70%" dialogTitle="附件预览"
                  @cancel="showFileDialog = false">
      <uploader ref="uploader" title="成绩上传" :is-show-tip="false" @getTableData="getTableData" accept=".jpg,.jpeg,.png,.pdf,.word,.doc,.docx,.xls,.xlsx,.txt,.ppt" fileSize="1024"
                :multiple="true" :fileOptions="fileOptions" updateName="updateName"
                @saveTableData="saveTableData" @deleteId="deleteId"></uploader>
    </CommonDialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
  getDetail,
  myTrainingDetail,
  saveUpdateScoreUpload, scoreUploadDelete, scoreUploadList,
  updateEducationQuestions
} from "@/api/training/trainingEducation";
import uploader from "@/components/file-upload/index";
import CommonDialog from "@/components/CommonDialog";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    uploader,
    CommonDialog
  },
  props: {
    type: {
      type: "String"
    },
    tainDetail: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      fileOptions: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        column: [
          {
            label: '文件名称',
            prop: "fileName",
            align: "left",
            overHidden: true,
            cell: true,
          },
          {
            label: '上传人',
            prop: "createUserName",
            align: "left",
            overHidden: true,
          },
        ]
      },
      dataForm: {},
      tableData: [],
      questionsData: [],
      dicData: [],
      showFileDialog: false,
      testQuestionsId: "",
      //视频列表展示字段
      subTableOption: {
        selection: false,
        customAddBtn: true,
        menuWidth: 100,
        column: [
          {
            label: "试题名称",
            prop: 'questionsName',
            align: "left",
            overHidden: true,
          },
          {
            label: "题量",
            prop: 'questionsQuantity',
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: '是否合格',
            prop: 'isQualified',
            type: 'switch',
            props: {
              label: "label",
              value: "value"
            },
            disabled: !['edit', 'add', 'additionalRecording'].includes(this.$route.query.type),
            dataType: 'string',
            align: "center",
            width: 180,
            overHidden: true,
            dicData: [
              {
                label: '否',
                value: "2"
              },
              {
                label: '是',
                value: "1",
              },
            ],
          },
          {
            label: "成绩",
            prop: 'achievement',
            align: "center",
            width: 180,
            overHidden: true,
            cell: true,
          },
        ]
      },
      doned: false
    }
  },
  computed: {
    subTableheadBtnOptions() {
      let buttonBtn = [];
      if (this.type == "additionalRecording" && this.tableData.length > 0) {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "knowledge",
        });
      }

      return buttonBtn;
    }
  },
  methods: {
    answerOutcome(row) {
      // 答题结果
      this.$router.push({
        path: '/safetyenment/train/myExam/examResults',
        query: {
          row: JSON.stringify({
            id: row.trainingEducationId,
            paperId: row.fileId,
            examId: this.dataForm.id,
            myTrainingTestId: row.id,
            type: 'view'
          }),
        }
      });
    },
    answer(row) {
      // 答题
      if (Number(this.dataForm.standardsHours) <= Number(this.dataForm.accomplishAccomplish) || Number(this.dataForm.standardsHours) == 0) {
        this.$router.push({
          path: '/business/safetyenment/train/trainingEducation/eduexampaper',
          query: {
            row: JSON.stringify({
              id: row.id,
              paperId: row.fileId.toString(),
              examId: this.dataForm.id,
              type: 'edit'
            }),
          }
        });
      } else {
        this.$message.warning("请完成培训的视频内容以达到达标课时再进行答题!");
      }
    },
    getTableData(data) {
      console.log("进行输出数据---------", data)
      //进行调用保存接口
      let cc = []
      var datum = data[data.length - 1];
      cc.push({
        fileId: datum.id,
        fileName: datum.name,
        path: datum.path,
        questionsId: this.testQuestionsId
      })
      saveUpdateScoreUpload(cc).then(res => {
        this.scoreUploadListAll();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      })
    },
    /**
     * 成绩上传
     */
    recordScores(res) {
      this.testQuestionsId = res.id
      this.showFileDialog = true;
      this.$nextTick(() => {
        this.scoreUploadListAll();
      })
    },
    scoreUploadListAll() {
      scoreUploadList(this.testQuestionsId).then(res => {
        if(res.data.data){
          res.data.data.forEach(e=>{
            e.name = e.fileName
          })
        }
        this.$refs.uploader.tableData = res.data.data;
        this.$refs.uploader.tableData.forEach((item, index) => {
          this.$refs.uploader.$refs.gridLayOut.getGrid().rowCell(item, index);
        });
      })
    },
    /**
     * 修改名称
     * @param data
     */
    saveTableData(data) {
      saveUpdateScoreUpload(data).then(res => {
        this.scoreUploadListAll();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      })
    },
    deleteId(row) {
      scoreUploadDelete(row).then((res) => {
        this.scoreUploadListAll();
        this.$message({
          message: "删除成功",
          type: "success",
        });
      })
    },
    /**
     * 进行修改我的考试数据
     */
    headSave(row) {

      let cc = [];
      if (row) {
        cc.push(row)
      } else {
        cc = this.tableData
      }
      let id = cc[0].trainingEducationId
      console.log("进行输出--123---",cc)
      const allAchievementsValid = cc.every(item => {
        const achievement = Number(item.achievement); // Convert to number
        return !isNaN(achievement) && achievement <= 0;
      });
      if (allAchievementsValid) {
        return this.$message({
          message: "补录的成绩不能为0",
          type: "warning",
        });
      }
      updateEducationQuestions(cc).then((res) => {
        this.tainDetail(id)
        this.detail(id)
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      })
    },
    /**
     * 查看详情
     * @param id
     */
    detail(id) {
      this.tableData3 = [];
      getDetail(id).then((res) => {
        this.dataForm = res.data.data;
        this.tableData = res.data.data.educationQuestions
        console.log('进行输出----', this.tableData)
      }).then(() => {
        this.dataForm.taskId
        myTrainingDetail(this.dataForm.taskId).then((res) => {
          let data = res.data.data
          this.questionsData = data.eduPaperList
          if (this.type == "additionalRecording") {
            this.tableData.forEach((item, index) => {
              this.$refs.gridLayoutVide.getGrid().rowCell(item, index);
            });
          }
        })
      })
    },
  },
  mounted () {
    this.doned = this.$route.query.doned && this.$route.query.doned==1
  }
}
</script>
<style scoped>

</style>
