var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "videoBox" }, [
    _c(
      "div",
      { staticClass: "videoTopBox" },
      [
        _vm.fileType != "video"
          ? _c("iframe", {
              ref: "fileIframe",
              staticStyle: { width: "100%", height: "50vh" },
              attrs: { src: _vm.videoUrl, frameborder: "0" },
            })
          : _vm._e(),
        _vm.fileType == "video"
          ? _c("video", {
              ref: "video",
              staticClass: "video",
              attrs: { disablePictureInPicture: "", src: _vm.videoUrl },
              domProps: { muted: false },
            })
          : _vm._e(),
        _vm.fileType == "video"
          ? _c("div", { staticClass: "timeBox" }, [
              _vm._v(
                _vm._s(_vm.formatTime(_vm.currentTime)) +
                  "/" +
                  _vm._s(_vm.formatTime(_vm.totalTime))
              ),
            ])
          : _vm._e(),
        _vm.fileType == "video"
          ? _c("el-button", {
              staticClass: "videoBtn",
              attrs: {
                type: "primary",
                icon: _vm.videoType
                  ? "el-icon-video-pause"
                  : "el-icon-video-play",
              },
              on: { click: _vm.videoPlay },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "name",
        style: {
          color: _vm.themeColor,
          borderTop: "2px solid" + _vm.themeColor,
          borderBottom: "2px solid" + _vm.themeColor,
        },
      },
      [_vm._v("\n    学习列表\n  ")]
    ),
    _c(
      "div",
      { staticClass: "videoBottomBox" },
      [
        _c("el-button", {
          staticClass: "moveButton",
          attrs: { type: "primary", icon: "el-icon-arrow-left", circle: "" },
          on: { click: _vm.leftArrow },
        }),
        _c("div", { staticClass: "videoBigSwiper" }, [
          _c(
            "div",
            { ref: "videoSwiper", staticClass: "videoSwiper" },
            _vm._l(_vm.videoUrlList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "videoLittleBox",
                  style: {
                    border:
                      _vm.videoActive == index
                        ? "1px solid" + _vm.themeColor
                        : "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.chooseVideo(item, index)
                    },
                  },
                },
                [
                  item.fileType == "video"
                    ? _c("video", {
                        staticClass: "littleVideo",
                        attrs: {
                          disablePictureInPicture: "",
                          src: item.attachUrl,
                        },
                      })
                    : _vm._e(),
                  item.fileType == "image"
                    ? _c("img", {
                        attrs: { src: require("@/assets/images/image.png") },
                      })
                    : _vm._e(),
                  item.fileType == "pdf"
                    ? _c("img", {
                        attrs: { src: require("@/assets/images/pdf.png") },
                      })
                    : _vm._e(),
                  item.fileType == "document"
                    ? _c("img", {
                        attrs: { src: require("@/assets/images/document.png") },
                      })
                    : _vm._e(),
                  item.fileType == "xls"
                    ? _c("img", {
                        attrs: { src: require("@/assets/images/xls.png") },
                      })
                    : _vm._e(),
                  item.fileType == "unknown"
                    ? _c("img", {
                        attrs: { src: require("@/assets/images/unknown.png") },
                      })
                    : _vm._e(),
                  item.whetherToWatch == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "learnName",
                          style: { color: _vm.themeColor },
                        },
                        [_vm._v("已学习")]
                      )
                    : _vm._e(),
                  item.whetherToWatch == 2
                    ? _c("div", { staticClass: "learnName" }, [
                        _vm._v("未学习"),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c("el-button", {
          staticClass: "moveButton",
          attrs: { type: "primary", icon: "el-icon-arrow-right", circle: "" },
          on: { click: _vm.rightArrow },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }