var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.subTableheadBtnOptions,
          "head-title": "",
        },
        on: { "head-save": _vm.headSave },
      }),
      _c("grid-layout", {
        ref: "gridLayoutVide",
        staticClass: "serviceType",
        attrs: {
          "table-options": _vm.subTableOption,
          "table-data": _vm.tableData,
          "table-loading": false,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                row.isQualified == 2 &&
                _vm.type != "view" &&
                _vm.type != "additionalRecording" &&
                _vm.type != "recordScores" &&
                !_vm.doned
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.answer(row)
                          },
                        },
                      },
                      [_vm._v(" 答题\n      ")]
                    )
                  : _vm._e(),
                row.isQualified == 1 &&
                _vm.type != "additionalRecording" &&
                _vm.type != "recordScores" &&
                row.storageMode != "additionalRecording"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.answerOutcome(row)
                          },
                        },
                      },
                      [_vm._v(" 答题结果\n      ")]
                    )
                  : _vm._e(),
                _vm.type == "additionalRecording" && !_vm.doned
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.headSave(row)
                          },
                        },
                      },
                      [_vm._v(" 保存\n      ")]
                    )
                  : _vm._e(),
                _vm.type == "recordScores" && !_vm.doned
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.recordScores(row)
                          },
                        },
                      },
                      [_vm._v(" 成绩上传\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.showFileDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showConfirmBtn: false,
                width: "70%",
                dialogTitle: "附件预览",
              },
              on: {
                cancel: function ($event) {
                  _vm.showFileDialog = false
                },
              },
            },
            [
              _c("uploader", {
                ref: "uploader",
                attrs: {
                  title: "成绩上传",
                  "is-show-tip": false,
                  accept:
                    ".jpg,.jpeg,.png,.pdf,.word,.doc,.docx,.xls,.xlsx,.txt,.ppt",
                  fileSize: "1024",
                  multiple: true,
                  fileOptions: _vm.fileOptions,
                  updateName: "updateName",
                },
                on: {
                  getTableData: _vm.getTableData,
                  saveTableData: _vm.saveTableData,
                  deleteId: _vm.deleteId,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }